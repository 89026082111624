.specialCard{
    background: #012;
    background-image: url(/public/images/transparent.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width:96%;
/*    margin:2%;*/
    margin: 25px 0px 25px 0px;
    padding:0;
    flex: 1;
    box-shadow:5px 5px 5px 5px #333;
    border-radius: 15px;
    transform: scale(0.98);
}

.specialCard:hover{
    box-shadow: 5px 10px 10px 5px #012;
    transform: scale(1);
}

.specialCard  ul{
    list-style: none;
}


.cell{ /*videos and others*/
    width: 390px;/**/
}/**/

.cell p{
    line-height: normal;
    overflow: hidden;
}

/* Small devices - smartphones */
@media screen and (min-width: 480px) {
	
}
/* Small devices - tables */
@media screen and (min-width: 768px) {
		
    .cell {
        width: 550px; 
        /*min-width: 29%;
        max-width: 29%;
        /*min-width: 390px;*/
    }
    /*.video p{
        height: 80px;
        max-height: 80px;
        min-height: 80px;
    }*/
        
	
}	

/* Medium devices - tables and pcs */
@media screen and (min-width: 960px) {
	

	
}
/* Large devices */
@media screen and (min-width: 1280px) {

}

