/* Main header */

.main-header {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    background-color: transparent; /*#405580;
	border-bottom: 2px solid #456;/**/
	width: 100%;
	height: 86px;/*
    float: left;*/
    top: 0;
    left: 0;
	padding: 0;
	opacity: 0.95;
	position: fixed;
	z-index:100;/*-*/
	transition: all 0.5s ease-in-out;
}
.main-header a{
    color: #f0f0f0;
	text-decoration: none;
}

.scrolled{
	background-color: #012;
	/*border-bottom: 1px solid #f00;*/
}

.logo {	
	width: 86px;
	height: 86px; 
	float: left;
	background: url(/public/images/logo-small.png) center center/56px no-repeat;
	font-size: 0;	
}

.btn-menu {
	display: block;
	border: 0px;
	margin: 1em 2em;	
	width: 36px;
	height: 36px; 
	/*float: right;*/
	text-align: center;
	color:#f0f0f0;
/*	border-radius: 56px;/**/
	cursor: pointer;
	background-color: transparent; /* #333;
	/*background: linear-gradient(to right,#405580,#333333);*/
}
.btn-menu:hover {	
	background: #f0f0f0;
	color: #f00; /* #333;*/
	transition-duration: 1s;
	/*background: linear-gradient(to left,#405580,#333333);*/
}
.btn-close:hover {	
	background: #f0f0f0;
	color: #f00; /* #333;*/
	transition-duration: 1s;
	/*background: linear-gradient(to left,#405580,#333333);*/
}

	
/* Menu */
.menu {
	display: none;
	width: 50%;
	height: 100%;
	position: fixed;
	background-color: #012; /*#333;*/
	opacity: 1;
	top: 0px; /*87px;*/
	right: 0px;
}

.btn-close {
	/*display: none;/**/
	font-size: 1.2em;
	color: #f0f0f0;
	float: right;
	cursor: pointer;
	margin: 2em 1.2em;/*5%;/**/
}



.menu ul {
	width: 100%; /** /
	margin-top: 3em;*/
	float: left;/**/
    text-align: left;
    list-style: none;
}

.menu ul li {
	padding: 0.5em 0em;/**/
}

.menu ul li a {
    text-decoration: none;
	font-size: 1.4em; 
	color: #f0f0f0;
	/*
	padding: 1.5% 0.2%;/**/
	transition: all 0.5s ease-in-out;
}

/*.menu li a:*/

.menu ul li a:hover {
	/*border: 1px solid #f0f0f0;*/
	text-decoration: underline;
}

.menu ul li a.active {
	color: #f00;
/*	text-decoration: underline;
*/}
.menu ul li a.active:hover{
	
	text-decoration: none;
}
	
/* Small devices - smartphones */
@media screen and (min-width: 480px) {
	
}
/* Small devices - tables */
@media screen and (min-width: 768px) {
		
	.main-header{
		position: fixed;
/*		background-color: transparent;*/
		border-bottom: none;
		top: 0;
		left: 0;/*
		z-index: 100;*/
		opacity: 0.90;
	}

	.scrolled{
		position: fixed;
	}

	.btn-menu {
		display: none !important;
	}
	.btn-close {
		display: none !important;
	}
	.menu {
		/**top: 0px;*/
		margin: 0em 2em;
		width: auto;
		height: 56px;
		line-height: 56px;
		/*float: right;*/
		display: block !important;
		position: static;
		background-color: transparent;/* #333;*/
		
	}
	.menu ul li { 
		padding: 0;
		float: left;
	}
	.menu ul li a { 
		font-size: 1em;
		padding: 15px;
	}
	.menu ul li a:hover { 
		border: none;
		text-decoration: underline;
	}

	
	
	
}	

/* Medium devices - tables and pcs */
@media screen and (min-width: 960px) {
	
	.logo {	background: #9f9777;
		width: 220px;
		height: 80px; 
		float: left;
		background: url(/public/images/logo-normal.png) center center/220px no-repeat;
		font-size: 0;	
	}

	
}
/* Large devices */
@media screen and (min-width: 1280px) {

}

