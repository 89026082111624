.footer{
    margin: 0em 0em;
}


.footerContainer{
	display: inline-block;
	max-width: 280px;
	text-align: center;
}


footer.footer{

    background-color: #333;
	border-top: 1px solid #012;
	width:100%;
	display: flex;
	flex-wrap: wrap;
	/*float:left;*/
	padding: 0;
    opacity: 0.9;
    position: relative;

}
footer p/*, footer h3 */{
	color: #f0f0f0;
}
footer h3 {
	color: #f00;
}
footer a {
	color: #f0f0f0;
	text-decoration: none;
}

footer section.one-three-cell h3{
	font-weight: bold;
}
footer section.one-three-cell p{
	text-align: center;
}

.footerContainer img{
	width: 86px;
	height: 86px;
	border: 1px solid #f0f0f0;
	border-radius: 50%;
}



footer.second-footer{
    display: flex;
    width: 100%;
   /* border-top: 2px solid #456;*/
    background-color: #012;
    max-height: 50px;
	height: 50px;
	text-align: left;
	font-size: small;
    opacity: 0.95;
    left: 0;
    bottom: 0;
    position: relative;
}

footer.second-footer section.left{	
	width: 15%;
	margin: 5px 0 0 0;
}
footer.second-footer section.right{	
	width: 85%;
	margin: 0px;
}

footer.second-footer section.left ul{
	margin: 1em;
}

footer.second-footer section.left ul.footer-menu li{
	display: none;
	/*font-size: 1.2em;*/ 
	color: #f0f0f0;
}

footer.second-footer section.left ul.footer-menu li a.active{
	text-decoration: underline;
}

footer.second-footer section.right p{
	text-align: right;
	margin-right: 15px;
}

#hide {
	display: none;
}
#footer-menu a:visited {
	color: #f0f0f0;
	text-decoration-color: brown;
}


/*======================= responsive =======================================*/
/* Small devices - smartphones */
@media screen and (min-width: 480px) {
	
}
/* Small devices - tables */
@media screen and (min-width: 768px) {

	

	footer.second-footer section.left {	
		width: 60%;
	}
	footer.second-footer section.right {	
		width: 40%;
	}
	footer.second-footer section.left ul.footer-menu li {
		padding: 5px;
		display: inline;
	}

}	
/* Medium devices - tables and pcs */
@media screen and (min-width: 960px) {
	
	#hide {
		display:block;
	}
}
/* Large devices */
@media screen and (min-width: 1280px) {

}