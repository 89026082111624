
.black-v{
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, #012 1%, transparent 99%);
   /**/
}
.black-h{
    width: inherit;
    height: inherit;
    background: linear-gradient(to right, #012 30%, transparent 70%);
    /**/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;

}