.page .publication a.special-btn {
  padding: 5px;
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 2rem; /*see here*/

}

.page .publication  {
  min-height: 150px;/*see here*/

}
.page .publication img {
  padding: 5px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
  line-height: 2rem; /*see here*/

}


.pageHeader .publications h1.title{
    font-size: 150%;
}

/*.page **/

.publications .pageContainer  .cell {
 min-width: 100%;
}


/*======================= responsive =======================================*/
/*0 Small devices - smartphones */
@media screen and (min-width: 480px) {
    .pageHeader .publications h1.title{
      font-size: 165%;
    
    }
    
  }
 
  @media screen and (min-width: 520px) {
    .pageHeader .publications h1.title{
      font-size: 180%;
    
    }
  }
  
  @media screen and (min-width: 560px) {
    .pageHeader .publications h1.title{
      font-size: 195%;
    
    }
  
  }
  
  @media screen and (min-width: 600px) {
    .pageHeader .publications h1.title{
      font-size: 210%;
    
    }
  
  }
  @media screen and (min-width: 640px) {
    .pageHeader .publications h1.title{
      font-size: 225%;
    
    }
 
 
  }

  @media screen and (min-width: 680px) {
    .pageHeader .publications h1.title{
      font-size: 240%;
    
    }
  
  }
  
  @media screen and (min-width: 720px) {
    .pageHeader .publications h1.title{
      font-size: 260%;
    
    }
  
  }
  
  @media screen and (min-width: 820px) {
    .pageHeader .publications h1.title{
      font-size: 305%;
    
    }
  
  }
 
  
 