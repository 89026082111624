.card{
    background: transparent;
    background-image: url(/public/images/transparent.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
    margin:0;
    padding:0;
    flex: 1;
}


.card ul{
    list-style: none;
}
