.notfound{
    margin: 0 0;
}

.notfound p {
    padding: 20px; 
    text-align: center;
  
}

