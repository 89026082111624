html, body {
  overflow-x:hidden 
} 

body{
  width: 100%;
  margin: 0px 0px;
	padding: 0px;
  background: #333;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
/**/}

a{
  text-decoration: none;
  font-weight: 900;
}

a:hover {
  text-decoration: underline;
}


p{
  line-height: 1.4;
  color: #ffffff;
}

h3, span.effect-1{
  color: #ff0000;
}/**/

.App{
  width: 100%;
  top: 0px;
  left: 0px;
}

section {
	padding:0;
}

/*
article {
	float: left;
	width: 100%;
	
	margin-bottom: 2em;
	padding:0;
}
*/
.one-three-cell, .one-two-cell, .one-cell {
  /*float: left;*/
	width: 100%;
	margin: 0px;
	text-align: center;
	padding:0;
}
  
.empty-bar {
  clear: both;
  margin: 0 auto;
/*  height: 86px; /*56*/
  /*padding: 0% 2%;*/
}

/*****************************************************************************************/
/**************************Redes Sociais e Contactos**************************************/
/*****************************************************************************************/
/*****************************************************************************************/
/*****************************************************************************************/

.social{
  margin: 0;
  padding: 0;
}
ul.social li {
      display: inline-block;
      margin: 2%;
      padding: 0;
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius:  50%;
      text-align: center;
      vertical-align: bottom;
      line-height: 30px;
     
}
ul.social li i{
/*height: 50px;
      */font-size: 120%;
      text-align: center;
      color:#333333;
      vertical-align: middle;
}
/*
ul.social li:hover, ul.social li:hover i {

      color:#ffffff;
      background: #333333;
}
*/
ul.social li:hover i.fa-facebook {
color: #3b5998;
}
ul.social li:hover i.fa-twitter {
color: #55acee;
}
ul.social li:hover i.fa-linkedin {
color: #007bb5;
}
ul.social li:hover i.fa-youtube {
color: #ff0000;
}
/*****************************************************************************************/
/*****************************************************************************************/
/*****************************************************************************************/
/*****************************************************************************************/

/*****************************************************************************************/
/*****************************************MEDIA ************************************************/
/*****************************************************************************************/
.media{
  position:relative;
  width:100%;
  height:auto;/*0*/
  padding-bottom:51%;
}


.media iframe{
  position:absolute;
  left:0;
  top:0;
  margin: 2% 0% 0% 2%;
  width:96%;
  height:90%;/*96*/
  box-shadow: 2px 2px 2px 2px #333;
  border-radius: 15px;
  z-index: 1;
}


.media img{
  display: block;
  position: absolute;
  margin: auto;
 /* margin-right: auto;
 */
  margin-top: 2%;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  width:auto;
  height:auto;
  z-index: 1;
  max-width: 96%;
  max-height: 90%;
  box-shadow: 2px 2px 2px 2px #333;
  border-radius: 15px;
}

.media p{
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}

.pmedia{
  line-height: normal;

}

/************************************************************************************************/
/******************************************Pages*************************************************/

.page{
  margin: 0 0;
  
}

.page p {
  margin: 20px;
  padding: 20px; 
  text-align: justify;

}

.page h3 {
  padding: 5px;
}

.page a {
  color: rgb(118, 179, 240);
  text-decoration: none;
  font-weight: 900;
}



.pageContainer{
  display: block;
  flex-wrap: wrap;
  justify-content: stretch;/* space-between;*/
  align-items: stretch;
  
}

/*.pageContainer.group{
  background-color: #012;
}/**/

/*.pageContainer*/
/*.listVideos{
  display: block;
  
}**/

.pageContainer p{
  flex: 1;
  
  
}


.page.pageContainer.media p{
  line-height: normal;
}


.pageHeader{
  color: #f0f0f0;
  text-shadow: 2px 2px #333;  
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #012 1%, transparent 99%);;
  overflow: hidden;
}


  

.page a.special-btn{
  text-decoration: none;
  color: #f0f0f0;
  background-color: #f00;
  padding: 20px;
  margin-top: 60px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 5rem; /*see here*/
  white-space: nowrap;
  border-radius: 60px;
  text-transform: uppercase;
  
}

.page a.special-btn:hover{
  background-color: transparent;
  box-shadow: 5px 5px 5px 0px #f00;
  border: 1px solid #f00;
  /*padding: 5px;
  /*font-size: 1.2rem;
  border-radius: 15px;*/
}

.page .pageHeader .pageHeaderContainer .pageContainer a.cir-btn{
  text-decoration: none;
  color: #f0f0f0;
  font-size: 280%;
  font-weight: bold;
  width: 60px;
  height: 60px;
  background-color: #f00;
  padding: 0;
  margin: 2%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  /**/line-height: 60px; /*see here*/
  border-radius: 50%;
  
}


.page .pageHeader .pageHeaderContainer .pageContainer a.cir-btn:hover{
  background-color: transparent;
  box-shadow: 5px 5px 5px 0px #f00;
  border: 1px solid #f00;
 
}


.page a.special-btn.youtube:hover{
    color: #ff0000;
}

.pageHeader h1.title{
  margin:  10px;
  padding-right: 30px;
  font-size: 180%;/**/
  text-align: left;
  padding-top: 86px; /*25px;*/ 
 /* text-transform: uppercase;*/

}

.page .pageHeader a{
  color: #f0f0f0;
}

.page .pageHeader p{
  font-size: 125%;
  padding: 10px;

}

.page .pageHeader p img{
  width: 100px;
  height: 100px;
  border-radius: 20px;
  align-items: center;
  align-content: center;
  text-align: center;

}

.div-with-video{
  z-index: 1;
}


.bg-video{
  top: 50%;
  left: 50%;
  display: none;/**/
  position: absolute;
  z-index: 0;/**/
  transform: translate(-50%,-50%);
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}

.page a:hover{
  text-decoration: underline; 

}


  .pageHeader .pageHeaderContainer{
    margin: 0;
    position: absolute;
    display: flex;
    align-items: right;    
    justify-content:right;
    flex-direction: column;
    /*max-width: 620px;
  /*background-color: #333;
 /* opacity: 0.3;
 /* */width: 100%;
  height: 90%;
  
  /*width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);*/

}

/*.pageHeader .pageHeaderContainer .pageHeaderDataContainer .left{
    
 
  

}
*/
.pageHeader .pageHeaderContainer p{
  margin-right: 60px;
  /*font-size: 15pt;*/
}
.listVideos.media p{
  line-height: normal;
  
}
.listVideos p{
  flex: 1;
  
}


.pageVideoContainer{
  display: flex;
  padding: 0px 30px 0px 30px;

  position: relative;  

}

.pageVideoContainer .listLeft,
.pageVideoContainer .listRight{
  position: absolute;
  height: 60px;
  width: 60px;
  color: #fff;
  font-size: 160%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.5s ease;

}
.listLeft,
.listRight{
top: 50%;
}
.pageVideoContainer .listLeft {
  left: 0;
}
.pageVideoContainer .listRight{
  right: 0;
}

.pageVideoContainer .listLeft:hover,
.pageVideoContainer .listRight:hover{
    color:#f00;
  }

.listVideos{
  display:inline-flex;
  transition: all 0.5s ease;
  
}


/*======================= responsive =======================================*/
/* Small devices - smartphones */
@media screen and (min-width: 480px) {
  .pageHeader h1.title{
    font-size: 190%;
  
  }
  /*.pageHeader .pageHeaderContainer p{

  font-size: 15pt;
  } */
  
}

@media screen and (min-width: 520px) {
  .pageHeader h1.title{
    font-size: 210%;
  
  }
}

@media screen and (min-width: 560px) {
  .pageHeader h1.title{
    font-size: 230%;
  
  }

}
@media screen and (min-width: 600px) {
  .pageHeader h1.title{
    font-size: 250%;
  
  }

}
@media screen and (min-width: 640px) {
  .pageHeader h1.title{
    font-size: 270%;
  
  }

}
@media screen and (min-width: 680px) {
  .pageHeader h1.title{
    font-size: 290%;
  
  }

}
@media screen and (min-width: 720px) {
  .pageHeader h1.title{
    font-size: 305%;
  
  }

}
/* Small devices - tables */
@media screen and (min-width: 768px) and (min-height:550px) {
  

  .pageContainer {
    display: flex;
  }
  
  .pageHeader h1.title{
    padding-top: 86px; /*25px;*/ 
  }
 
  .pageHeader .pageHeaderContainer{
    margin: 0;
    position: absolute;
    display: flex;
    align-items: left;    
    justify-content: center;
    flex-direction: column;
   /* background-color: #333;
    opacity: 0.3;
   /* */width: 100%;
    height: 100%;
    /*width: 100%;*/
   /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);/**/
  
  }
  
  .pageHeader .pageHeaderContainer .pageContainer p{
   max-height: 100%;
   overflow: hidden;
  }

  .pageVideoContainer{
    display: flex;
    padding: 0px 30px 0px 30px;
  
    position: relative;  
  
  }
  
  /*
  .pageVideoContainer .listLeft,
  .pageVideoContainer .listRight{
    opacity: 0;
  }
  .pageVideoContainer:hover .listLeft,
  .pageVideoContainer:hover .listRight{
    opacity: 1;
  }
 /**/  

  
	.bg-video{
    display: none;/* block;*/
  }
  

  .one-two-cell, #video-two-cols {
		width: 46%;
		margin: 2% 2%;
  }
  
  .one-three-cell, .links, #video {
		width: 29%;
		margin: 2% 2%;
	}

	
}	
/* Medium devices - tables and pcs */
@media screen and (min-width: 960px) {

 /*
  .page .pageHeader p{
   /* max-width: 65%;* //* remove* /

  }*/
  .pageVideoContainer .listLeft,
  .pageVideoContainer .listRight{
    opacity: 0;
  }
  .pageVideoContainer:hover .listLeft,
  .pageVideoContainer:hover .listRight{
    opacity: 1;
  }
  
	
}
/* Large devices */
@media screen and (min-width: 1280px) {

}
